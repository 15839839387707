export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Omit<ErrorProps, 'statusCode'> = {
  title: '¡Ups! Algo ha ido mal',
  subheader: 'La página que buscas no se ha podido cargar.',
  buttonText: 'Continúa en la página inicial',
}
